<template>
  <div class="p-ArtIndex">
    <form action="/">
      <van-search
        v-model="s"
        shape="round"
        placeholder="输入艺术家/作品名/作品编号"
        :show-action="sVisible"
        @focus="onFocus"
        @clear="sDatas = []"
        @cancel="onCancel"
        @search="onSearch"
        @input="sOnInput"
      />
    </form>
    <div class="NotSearch" v-if="!sVisible">
      <van-sticky>
        <div class="filters van-hairline--bottom">
          <van-dropdown-menu>
            <van-dropdown-item :title="activeType[1]" ref="opusType">
              <div class="opusType">
                <van-row gutter="8">
                  <van-col v-for="t in types" span="6" :key="t[0]">
                    <span
                      :class="[
                        'opusType-item',
                        { active: t[0] === activeType[0] }
                      ]"
                      @click="onTypeChange(t)"
                    >{{ t[1] }}</span>
                  </van-col>
                </van-row>
              </div>
            </van-dropdown-item>
          </van-dropdown-menu>
          <span class="center">检索到{{ totalCount }}件作品</span>
          <span
            class="pull-right"
            @click="filterVisible = true"
          >筛选
            <van-icon class-prefix="icon-artalliance" name="filter" size="16" />
          </span>
        </div>
      </van-sticky>
      <a-spin :spinning="loading">
        <div class="context" v-if="datas.length">
          <van-row gutter="15">
            <van-col
              v-for="i in datas"
              @click="$router.push({ name: 'art', params: { id: i.opusId }})"
              span="12"
              :key="i.opusId"
            >
              <art-card-art
                :name="i.title"
                width="100%"
                height="120"
                :src="i.thumbnailUrl"
              >
                <span>{{ i | subTitle }}</span>
                <!-- <van-image width="18" height="18" :src="i.avatar" round />
                <span>{{ i.authorName }}</span> -->
              </art-card-art>
            </van-col>
          </van-row>
          <van-pagination
            v-model="page"
            :page-count="pageCount"
            mode="simple"
            @change="fetchData"
          />
        </div>
        <van-empty v-else description="暂无作品" />
      </a-spin>
    </div>
    <div class="Search" v-else>
      <div class="Empty" v-if="sEmpty">
        <div style="margin-right: -10px">
          <h3>历史搜索
            <span class="pull-right" style="margin-right: 10px">
              <van-icon name="delete" size="16" @click="clearHistory" />
            </span>
          </h3>
          <van-button
            v-for="i in sHistory"
            @click="clickOnHistory(i)"
            size="small"
            :key="i"
          >{{ i }}</van-button>
        </div>
      </div>
      <div class="NotEmpty" v-else>
        <van-tabs
          v-model="sType"
          line-width="60"
          line-height="6"
          animated
        >
          <van-tab
            v-for="({ name, title }) in tabs"
            :title="title"
            :name="name"
            :key="name"
          >
            <component :is="name"></component>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <a-drawer
      placement="right"
      wrap-class-name="drawer-filter"
      :visible="filterVisible"
      :closable="false"
      width="calc(100vw - 55px)"
      @close="filterVisible = false"
    >
      <div>
        <template
          v-for="(o, $index) in [
            {
              name: '设计风格',
              key: 'designType'
            },
            {
              name: '色系',
              key: 'colorType'
            },
            {
              name: '适合场景',
              key: 'sceneType'
            },
            {
              name: '作品主体',
              key: 'subjectType'
            }
          ]"
        >
          <div
            v-if="filters[o.key]"
            :key="$index"
          >
            <h5>{{ o.name }}</h5>
            <art-radio-group
              v-model="filters[o.key].value"
              :options="filters[o.key].options"
              :span="8"
              :gutter="10"
              multiple
            />
          </div>
        </template>
        <template v-if="filters.widthSize">
          <h5>尺寸（宽度）</h5>
          <art-radio-group
            v-model="filters.widthSize.value"
            :options="filters.widthSize.options"
            :gutter="10"
          />
        </template>
        <template v-if="filters.price">
          <h5>参考价</h5>
          <div class="range">
            <input
              v-model="filters.price.min"
              placeholder="最低价"
            />
            <span>-</span>
            <input
              v-model="filters.price.max"
              placeholder="最高价"
            />
          </div>
        </template>
        <div class="btns">
          <van-button type="info" @click="reset" plain size="small">重&nbsp;置</van-button>
          <van-button type="info" @click="fetchData" size="small">确&nbsp;定</van-button>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import store from '@/store'
import request from '@/api/request'
import utils from '@/utils'
import { Art, Artist } from './tab-components'

import {
  OPUS_TYPE,
  HISTORY_COLLENCTION,
  HISTORY_LIMIT,
  DEFAULT_AVATAR,
  ART_SIZE
} from '@/config/constant'

export default {
  data () {
    const fullOpusType = {
      0: '全部分类',
      ...OPUS_TYPE
    }
    const types = Object.keys(fullOpusType).map(
      type => ([Number(type), fullOpusType[type]])
    )

    types.splice(1, 0, [-1, '精选作品'])

    const tabs = [
      { title: '作品', name: 'art' },
      { title: '艺术家', name: 'artist' }
    ]

    return {
      tagList: {},
      // opusType
      types,
      activeType: types[0],
      // pager
      datas: [],
      loading: false,
      page: 1,
      pageSize: 20,
      pageCount: 0,
      totalCount: 0,
      // filters
      filters: {},
      filterVisible: false,
      // tabs
      tabs,
      sType: 0,
      // search
      s: '',
      sPrev: '',
      sEmpty: true,
      sHistory: [],
      sVisible: false,
      sDatas: [],
      sOnInput: null
    }
  },
  watch: {
    sType (type) {
      if (type !== undefined) {
        const { name, query } = this.$route

        this.$router.push({
          name,
          query: {
            ...query,
            type
          }
        })
      }
    }
  },
  methods: {
    onTypeChange (type) {
      this.activeType = type

      const trigger = this.$refs.opusType

      if (trigger) {
        this.page = 1
        // TODO
        this.fetchData()
        trigger.toggle()
      }
    },
    reset () {
      this.filters = utils.cloneDeep(this.initialFilters)
      this.$nextTick(this.fetchData.bind(this))
    },
    fetchData () {
      const { activeType, page, pageSize, filters } = this
      const {
        price: {
          min,
          max
        },
        ...others
      } = filters

      const params = {
        currPage: page,
        pageSize
      }

      if (activeType[0] === 0) {
        params.opusType = undefined
      } else if (activeType[0] === -1) {
        params.isDeposit = 1
      } else {
        params.opusType = activeType[0]
      }

      if (min !== '') {
        params.priceBegin = Number(min)
      }

      if (max !== '') {
        params.priceEnd = Number(max)
      }

      Object.keys(others).forEach(key => {
        const values = filters[key].value.slice()

        if (Array.isArray(values) && values.length) {
          if (key === 'widthSize') {
            const oKey = values.map(({ name }) => name).join(',')

            if (oKey && ART_SIZE[oKey]) {
              params[key] = ART_SIZE[oKey]
            }
          } else {
            params[key] = values.map(({ name }) => name).join(',')
          }
        }
      })

      this.loading = true
      request.getList(params).then(res => {
        const {
          totalCount,
          totalPage,
          list = []
        } = res

        this.totalCount = totalCount
        this.pageCount = totalPage
        this.datas = list.map(o => {
          if (!o.avatar) {
            o.avatar = DEFAULT_AVATAR
          }

          return o
        })

        this.filterVisible = false
        utils.scrollTo(0)

        this.loading = false
      })
    },
    onSearch () {
      const s = utils.trim(this.s)

      if (
        (this.sEmpty = s === '') ||
        (this.sPrev === s)
      ) {
        return
      }

      const history = this.sHistory.slice()
      const idx = history.indexOf(s)

      if (idx > -1) {
        history.splice(idx, 1)
      }

      history.push(s)
      this.sHistory = history.slice(0, HISTORY_LIMIT)

      const { name, query } = this.$route
      const encode = window.encodeURIComponent(s)

      this.$router.push({
        name,
        query: {
          ...query,
          s: encode
        }
      })

      this.sPrev = s
    },
    onFocus () {
      const history = this.$ls.get(HISTORY_COLLENCTION)

      this.sHistory = Array.isArray(history) ? history : []
      this.sVisible = true
    },
    // TODO
    onCancel () {
      this.sVisible = false
      this.saveHistory()

      this.page = 1
      this.fetchData()

      this.$router.push({ name: this.$route.name })
      this.sDatas = []
    },
    clickOnHistory (s) {
      this.s = s
      this.onSearch()
    },
    saveHistory () {
      this.$ls.set(HISTORY_COLLENCTION, this.sHistory)
    },
    clearHistory () {
      this.$ls.set(HISTORY_COLLENCTION, this.sHistory = [])
    }
  },
  components: { Art, Artist },
  beforeRouteEnter (to, from, next) {
    store.dispatch('GetTags', tags => {
      return next(vm => {
        // add initial value
        for (const o in tags) {
          tags[o].value = tags[o].value || []
        }

        vm.initialFilters = {
          widthSize: {
            options: Object.keys(ART_SIZE),
            value: []
          },
          price: {
            min: '',
            max: ''
          },
          ...tags
        }
        // set filter before Enter
        vm.filters = utils.cloneDeep(vm.initialFilters)

        vm.fetchData()
        vm.sOnInput = utils.throttle(
          vm.onSearch.bind(vm),
          2000, {
            leading: true,
            trailing: true
          }
        )
      })
    })
  },
  created () {
    // TODO
    const active = this.$route.query.type

    if (active !== undefined && (active !== this.activeType[0])) {
      for (let i = 0; i < this.types.length; i += 1) {
        if (active === this.types[i][0]) {
          this.activeType = this.types[i]
          break;
        }
      }
    }
  },
  beforeDestroy () {
    this.saveHistory()
  }
}
</script>

<style lang="less">
.p-ArtIndex {
  min-height: 100vh;
  background-color: #F7F8FA;

  .van-search {
    padding: 10px 16px;
  }

  .filters {
    background-color: #fff;
    padding: 0 18px;
    position: relative;

    .opusType {
      padding: 15px;
    }

    .opusType-item {
      background-color: #F7F8FA;
      border-radius: 2px;
      width: 100%;
      font-size: 13px;
      color: #323233;
      line-height: 32px;
      display: inline-block;
      text-align: center;
      margin-bottom: 12px;

      &.active {
        background-color: #040404;
        color: #fff;
      }
    }

    & > .van-dropdown-menu {
      height: 36px;
      display: inline-block;
      padding-top: 9px;

      &:after {
        content: none;
      }

      .van-dropdown-menu__title {
        font-size: 13px;
      }
    }

    .center {
      position: absolute;
      top: 9px;
      text-align: center;
      left: 6em;
      right: 6em;
      margin: auto;
      color: #B4B4B4;
    }

    & > span {
      font-size: 13px;
      line-height: 18px;
      display: inline-block;
    }

    .pull-right {
      margin-top: 9px;

      .icon-artalliance {
        vertical-align: top;
      }
    }
  }

  .Search {

    .van-tabs__wrap {
      padding: 0 12.5%;
      margin: 0 auto 4px;
      background-color: #fff;

      &:after {
        content: none;
      }
    }

    .van-tabs__line {
      bottom: 50%;
      margin-bottom: -2px;
      background-color: #B4B4B4;
      border-radius: 0 !important;
      opacity: .5;
    }
  }

  .NotSearch {

    .filter {
      position: absolute;
      background-color: #f8f8f8;

      .van-sidebar {
        width: 100px;
        text-align: center;

        + div {
          position: absolute;
          top: 0;
          left: 100%;
          width: calc(100vw - 100px);
          padding: 16px 12px 0;
          box-sizing: border-box;
          background-color: #fff;

          > span {
            color: #aaa;
            font-size: 12px;
            line-height: 18px;
            display: inline-block;
            vertical-align: top;
          }
        }
      }
    }
  }

  .Empty {
    padding: 20px;
    min-height: calc(100vh - 54px - @tabbar-height);
    background-color: #fff;

    h3 {
      margin-bottom: 16px;
    }

    h5 {
      margin-right: 10px;
    }

    .van-button {
      margin-bottom: 10px;
      margin-right: 10px;
      color: #9E9E9E;
      background-color: #F7F8FA;
      border-color: #F7F8FA;
      border-radius: 2px;
    }
  }

  .NotEmpty {
    min-height: calc(100vh - 54px - 44px - @tabbar-height);
  }

  .context {
    padding: 20px 20px 20px + @tabbar-height + 36px;

    .van-pagination {
      margin-bottom: 0;
    }

    .CardArt {
      margin-bottom: 20px;
      display: block;

      .van-multi-ellipsis--l2 {

        .van-image {
          vertical-align: top;
          margin-right: 4px;

          + span {
            width: calc(100% - 22px);
            word-break: break-all;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }
}
</style>

<style lang="less">
.s-Art {
  padding: 15px 20px;
  background-color: #fff;
  margin-bottom: 2px;
  font-size: 0;

  .van-image {

    img {
      border-radius: 4px;
    }

    & + div {
      width: calc(100% - 120px);
      padding-left: 15px;
      display: inline-block;
      vertical-align: top;

      .t {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 8px;
      }

      .sub {
        color: #B4B4B4;
        font-size: 12px;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.s-Artist {
  padding: 15px 20px;
  background-color: #fff;
  margin-bottom: 2px;
  font-size: 0;

  .van-image + div {
    width: calc(100% - 70px);
    padding-left: 15px;
    display: inline-block;
    vertical-align: top;

    .t {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 4px;
    }

    .sub {
      color: #B4B4B4;
      font-size: 12px;
      margin-bottom: 8px;
    }

    p {
      color: @gray-8;
      font-size: 12px;
      margin-bottom: 0;
    }
  }
}

.drawer-filter {

  .ant-drawer-body {
    padding: 25px 15px 30px;
  }

  h5 {
    margin-bottom: 15px;
  }

  .RadioGroup {
    margin-bottom: 20px;
  }

  .van-col > .van-button {
    background-color: #F7F8FA;
    border-radius: 15px;
    border: 0;
    color: #323233;
    font-size: 12px;

    &.van-button--info {
      background-color: #040404;
      color: #fff;
    }
  }

  .van-cell {
    font-size: 12px;
    padding: 5px 0;
  }

  .range {
    margin-bottom: 20px;

    input {
      width: calc(50% - 20px);
      font-size: 12px;
      line-height: 18px;
      padding: 6px;
      border-radius: 15px;
      background-color: #F7F8FA;
      border: 0;
      text-align: center;
    }

    span {
      width: 40px;
      text-align: center;
      display: inline-block;
      line-height: 30px;
      vertical-align: top;
    }
  }

  .btns {
    text-align: right;
    padding-top: 40px;

    .van-button {
      padding: 0 20px;
      margin-left: 10px;
    }
  }
}
</style>