<template>
  <div class="p-Art">
    <a-spin :spinning="spinning">
      <div class="top-left--btn">
        <van-icon class-prefix="icon-artalliance" name="left" @click="() => $router.back()" />
      </div>
      <div class="top-right--btn">
        <van-icon class-prefix="icon-artalliance" name="share" @click="shareVisible = true" />
      </div>
      <van-swipe v-if="datas.fileList && datas.fileList.length" :autoplay="3000" @change="onChange" class="art-gallery">
        <van-swipe-item
          v-for="(i, $index) in datas.fileList"
          :key="$index"
          @click="onClick($index)"
        >
          <van-image width="100vw" height="360px" fit="cover" :src="i" />
        </van-swipe-item>
        <template #indicator>
          <span class="indicator">
            {{ current + 1 }} / {{ datas.fileList.length }}
          </span>
          <span class="art-code">作品编号 {{ datas.opusCode }}</span>
        </template>
      </van-swipe>
      <div class="block top">
        <div class="main">
          <h2>{{ datas.title }}
            <span v-if="datas.status === 2">已售出</span>
            <span v-if="datas.status === 3">已买断</span>
            <span v-if="datas.status === 4">已下架</span>
          </h2>
          <p class="sub">{{ datas.subTitle }}</p>
          <p class="sub">
            <template v-if="datas.opusId">
              <van-button
                v-if="datas.isShared === 0"
                icon="add-o"
                type="info"
                :disabled="datas.status === 3 || datas.status === 4"
                :loading="busyOnCart"
                @click="add"
              >加入购物车</van-button>
              <van-button
                v-if="datas.isShared === 1"
                icon="close"
                type="info"
                :disabled="datas.status === 3 || datas.status === 4"
                :loading="busyOnCart"
                @click="remove"
              >从购物车移除</van-button>
            </template>
            <van-button
              :class="['fav', { collected: datas.isCollectioned === 1 }]"
              icon="star"
              type="info"
              :loading="busyOnFav"
              @click="updateFavor"
            >{{ datas.isCollectioned === 1 ? '已收藏' : '收藏' }}</van-button>
          </p>
          <div class="author" @click="$router.push({ name: 'user-page', params: { id: datas.authorId } })">
            <van-image
              :src="datas.authorAvatar"
              :show-loading="false"
              width="60"
              height="60"
              fit="cover"
              round
            />
            <span>{{ datas.author }}</span>
          </div>
        </div>
        <van-divider />
          <div class="tags">
            <van-tag v-for="i in datas.opusProperties" :key="i">{{ i }}</van-tag>
          </div>
      </div>
      <div class="blockquote">
        <p class="blockquote__context q" v-if="userInfo.roleInfo === 2">
          <strong>参考价</strong><span v-filter-html="priceComputed"></span>
          <!-- <em>{{ (localData.suggestPrice || 0) | money }}</em><sub>至</sub><em>{{ localData.suggestPrice1 || 0 | money }}</em> -->
          <van-tag class="pull-right" v-if="localData.levelTag && localData.suggestPrice !== 0">{{ localData.levelTag }}</van-tag>
        </p>
        <p class="blockquote__context s">
          <strong>艺术建议 / </strong>{{ datas.artSuggest }}
        </p>
        <div class="blockquote__tags">
          <p class="y">
            <span v-for="i in datas.oTags" :key="i">
              <van-icon name="passed" />
              {{ i }}
            </span>
          </p>
          <!-- <div class="n">
            <span>
              <van-icon name="info-o" />
              不支持7天无理由退货
            </span>
          </div> -->
        </div>
      </div>
      <div class="block note">
        <h3>创作手记</h3>
        <p class="text">{{ datas.note }}</p>
      </div>
      <!-- <div class="block">
        <h3>实景效果</h3>
      </div> -->
      <div class="blockquote other" v-if="otherDatas.length">
        <h3>作者的其他作品
          <a class="pull-right" @click="goUserPage">查看全部
            <van-icon name="arrow" />
          </a>
        </h3>
        <van-swipe
          :loop="false"
          :show-indicators="false"
          :width="90 + 15"
        >
          <van-swipe-item v-for="i in otherDatas" :key="i.opusId">
            <van-image
              width="90"
              height="90"
              :src="i.thumbnailUrl+'!thumbnail'"
              cross-origin="anonymous"
              @click="$router.push({ name: 'art', params: { id: i.opusId } })"
            />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="block tips">
        <h3>温馨提示</h3>
        <div class="hairlineCell van-button--hairline van-hairline--surround">
          <p><van-icon name="star-o" />避光</p>
          <p>应尽量避免正对着大型窗户悬挂作品，阳光直射暴晒时会引起作品褪色，特制的防紫外线涂层玻璃和亚克力可以帮助降低这个风险。</p>
        </div>
        <div class="hairlineCell van-button--hairline van-hairline--surround">
          <p><van-icon name="star-o" />悬挂储存</p>
          <p>房间悬挂艺术品，应该确认所选用零配件及玻璃的安全性，确保五年检查一次作品的悬挂系统，儿童房间可使用亚克力板。如收藏储存建议使用樟木箱子保存。</p>
        </div>
        <div class="hairlineCell van-button--hairline van-hairline--surround">
          <p><van-icon name="star-o" />防潮防风</p>
          <p>最需要注意的因素是湿度和温度。最好的温度条件是在18 摄氏度—23 摄氏度。恒温、恒湿，如悬挂请避免直对空调口，防止画面干裂。如收藏储存建议使用樟木箱子保存。</p>
        </div>
        <div class="hairlineCell van-button--hairline van-hairline--surround">
          <p><van-icon name="star-o" />清洁</p>
          <p>小心掸去或使用柔软抹布，抹去画上的灰尘，而不是冒险去使用水或者清洁剂去清洗（如果产生大面积污渍，请联系专业人员进行维护）</p>
        </div>
      </div>
      <!-- <a-back-top class="flow-favor" :visibility-height="200" v-if="userInfo.roleInfo === 2">
        <span :class="['flow-favor__inner', { collected: datas.isCollectioned === 1 }]">
          <van-icon name="star" size="20" @click="updateFavor" />
        </span>
      </a-back-top> -->
      <!-- <a-affix :offset-bottom="70">
        <div class="flow-favor">
          <span :class="['flow-favor__inner', { collected: datas.isCollectioned === 1 }]">
            <van-icon name="star" size="20" @click="updateFavor" />
          </span>
        </div>
      </a-affix> -->
      <art-widget-share v-model="shareVisible" :poster-option="posterOption" />
      <!-- <art-poster-art
        :imgUrl="t.imgUrl"
        :title="t.title"
        :subTitle="t.subTitle"
        :qrUrl="t.qrUrl"
      /> -->
    </a-spin>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
import request, { getQr } from '@/api/request'
import utils from '@/utils'
import documentTitle from '@/mixins/document-title'
import { mapGetters } from 'vuex'

import {
  OPUS_TYPE,
  DEFAULT_AVATAR
} from '@/config/constant'

export default {
  mixins: [documentTitle],
  data () {
    const posterOption = {
      name: '作品海报',
      // icon: 'poster',
      icon: '/static/image/icon-poster.png',
      posterComponent: 'art-poster-art',
      beforeCreatePoster: () => new Promise((resolve) => {
        const {
          thumbnailUrl,
          title,
          subTitle,
          author
        } = this.datas || {}

        const props = {
          imgUrl: thumbnailUrl,
          title,
          subTitle: subTitle ? `${subTitle} / ${author}` : subTitle
        }

        const qrUrl = getQr({
          code: document.location.origin + this.$route.fullPath,
          width: 80,
          height: 80
        })

        resolve({ qrUrl, ...props })
      })
    }

    return {
      localData: {
        effectDrawingList: []
      },
      localOtherData: {},
      current: 0,
      spinning: true,
      busyOnCart: false,
      busyOnFav: false,
      shareVisible: false,
      posterOption
      // t: {
      //   imgUrl: 'https://oss.resource.byzx.art/yiyou/20200309/1583717675926.jpg',
      //   qrUrl: '/api/app/qr?code=http:%2F%2Flocalhost:8080%2Fart%2F63048083738865664&width=80&height=80',
      //   subTitle: '油画 / 41x35cm / 2020 / 马国彬',
      //   title: '高地'
      // }
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ]),
    datas () {
      const {
        opusId,
        createTime,
        author,
        opusType,
        height,
        width,
        years,
        isDeposit, // 官方推荐
        isCollectionCertificate, // 包含收藏证书
        isDress, // 已装裱
        isTrusteeship, // 0: 艺术家自持, 1: 平台托管
        ...s
      } = this.localData

      if (opusId === undefined) {
        return {}
      }

      const subTitle = [
        OPUS_TYPE[opusType],
        `${width}x${height}cm` // TODO: 0
      ]

      if (years > 0) {
        subTitle.push(`${years}`)
      }

      const oTags = []

      if (isDeposit) {
        oTags.push('官方推荐')
      }
      if (isCollectionCertificate) {
        oTags.push('包含收藏证书')
      }
      if (isDress) {
        oTags.push('已装裱')
      }
      if (isTrusteeship === 1) {
        oTags.push('平台托管')
      } else if (isTrusteeship === 0) {
        oTags.push('艺术家自持')
      }

      const {
        effectDrawingList,
        thumbnailUrl,
        artSuggest
      } = this.localData

      const fileList = effectDrawingList

      if (thumbnailUrl) {
        fileList.unshift(thumbnailUrl)
      }

      if (utils.isEmpty(artSuggest)) {
        s.artSuggest = '请联系专业一对一艺术顾问，提供艺术建议'
      }

      return {
        opusId,
        createdAt: utils.fromNow(createTime),
        author: author.displayName || '无',
        authorAvatar: author.avatar || DEFAULT_AVATAR,
        authorId: author.authorId,
        subTitle: subTitle.join(' / '),
        oTags,
        fileList,
        ...s
      }
    },
    otherDatas () {
      return this.localOtherData;
    },
    priceComputed () {
      const {
        suggestPrice,
        suggestPrice1
      } = this.localData

      let s = ''

      if (suggestPrice > 0) {
        s += `<em>￥${this.$options.filters.money(suggestPrice)}</em>`
      }

      if (suggestPrice1 > 0 && (suggestPrice !== suggestPrice1)) {
        s += `<sub>-</sub><em>${this.$options.filters.money(suggestPrice1)}</em>`
      }

      return s ? s : `<b>收藏溢价<br><sub>请联系平台经纪人</sub></b>`
    }
  },
  watch: {
    '$route' (val) {
      this.fetchData(val.params.id)
    }
  },
  methods: {
    onChange (index) {
      this.current = index
    },
    onClick (index) {
      ImagePreview({
        images: this.localData.effectDrawingList,
        startPosition: index || 0,
        closeable: true,
      });
    },
    updateFavor () {
      const id = this.$route.params.id
      const updateRequest = this.datas.isCollectioned === 0
        ? request.addToFavorite
        : request.removeFromFavorite

      this.busyOnFav = true
      if (id !== undefined) {
        updateRequest({
          opusId: id
        }).then(this.fetchData.bind(this, id))
      }
    },
    fetchData (id) {
      if (id !== undefined) {
        // TODO: types
        this.spinning = true
        request.getArt({ opusId: id }).then(res => {
          let {
            opus: {
              effectDrawingList = [],
              ...restOpus
            } = {},
            otherOpus = {}
          } = res || {}

          if (!Array.isArray(effectDrawingList)) {
            effectDrawingList = []
          }

          this.localData = {
            effectDrawingList,
            ...restOpus
          }

          const { title } = this.localData

          if (title !== undefined) {
            this.changeTitle(`不一致选 - ${title}`)
          }

          this.localOtherData = otherOpus

          this.spinning = false
          this.busyOnCart = false
          this.busyOnFav = false
        })
      }
    },
    add () {
      const id = this.$route.params.id

      if (id !== undefined) {
        this.busyOnCart = true
        request.addToCart({ opusId: id })
          .then(() => {
            this.$notify({
              type: 'success',
              message: '已加入清单'
            })
            this.fetchData(id)
          })
      }
    },
    remove () {
      const id = this.$route.params.id

      if (id !== undefined) {
        this.busyOnCart = true
        request.removeFromCart({ opusId: id })
          .then(() => {
            this.$notify({
              type: 'success',
              message: '已从清单中移除'
            })
            this.fetchData(id)
          })
      }
    },
    goUserPage () {
      const id = this.localData.author &&
        this.localData.author.authorId

      if (id) {
        this.$router.push({
          name: 'user-page', params: { id }
        })
      }
    }
  },
  created () {
    this.$store.dispatch('$$GetInfo')
    this.fetchData(this.$route.params.id)
  }
}
</script>

<style lang="less">
.p-Art {
  background-color: #fff;
  padding-bottom: @tabbar-height;

  .art-gallery {
    line-height: 0;
  }

  .indicator, .art-code {
    color: #fff;
    font-size: 12px;
    line-height: 24px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    background-color: rgba(0, 0, 0, .3);
    border-radius: 11px;
    padding: 0 10px;
    text-align: center;
  }

  .indicator {
    right: 50%;
    width: 54px;
    margin-right: -27px;
  }
  
  h2 {
    color: @primary-color;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 10px;

    span {
      color: #B4B4B4;
      font-size: 12px;
      background-color: #F7F8FA;
      border-radius: 2px;
      padding: 2px 6px;
    }
  }

  h3 {
    color: @primary-color;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 12px;

    & > a {
      color: #B4B4B4;
      font-size: 12px;
      font-weight: normal;
    }

    .van-icon {
      line-height: 24px;
      vertical-align: top;
    }
  }

  .block {
    padding: 20px 22px;

    .sub {
      font-size: 14px;
      line-height: 18px;
      color: #9c9c9c;
      margin-bottom: 15px;

      .van-image {
        vertical-align: top;
        margin: 2px 4px 2px 0;
      }

      button {
        border-radius: 2px;
        padding: 0 8px;
        background-image: linear-gradient(147deg, #606060 7%, #040404 100%);
        border: none;
        margin-right: 10px;
      }

      .van-button {
        font-size: 13px;
        line-height: 28px;
        height: 28px;
      }

      .fav {
        color: #9c9c9c;
        background: #F7F8FA;
        // box-shadow: 0px 0px 2px rgba(0,0,0,.15);

        &.collected {
          color: #CC4E3F;
          background: #FFECE5;
          box-shadow: none;
        }
      }
    }

    .author {
      position: absolute;
      top: 24px;
      right: 6px;
      font-size: 14px;
      cursor: pointer;
      line-height: 0;
      text-align: center;

      & > span {
        width: 92px;
        display: block;
        text-align: center;
        color: #9c9c9c;
        line-height: 18px;
        margin-top: 10px;
      }
    }

    .text {
      font-size: 14px;
      color: #9c9c9c;
      line-height: 24px;
      margin-bottom: 0;
    }

    .hairlineCell {
      padding: 12px 12px 6px;
      border-color: #ddd;
      margin-bottom: 10px;

      p {
        font-size: 12px;
        margin-bottom: 6px;
        word-break: break-word;
        color: #9c9c9c;

        &:first-child {
          color: #484848;
          font-weight: bold;
        }
      }

      .van-icon {
        vertical-align: top;
        margin-top: 2px;
        margin-right: 4px;
      }
    }

    .van-divider {
      margin: 20px 0;
      border-color: fade(@primary-color-grey, 20)
    }

    .tags {
      margin-right: -10px;
      margin-bottom: -10px;

      .van-tag {
        margin-right: 10px;
        margin-bottom: 10px;
        font-size: 12px;
        color: #040404;
        background-color: #F7F8FA;
        border-radius: 2px;
        padding: 0 8px;
        line-height: 22px;
      }
    }
  }

  .top {
    position: relative;

    .main {
      padding-right: 75px;
    }
    
  }

  .blockquote {
    background-color: #F7F8FA;
    padding: 20px 22px 20px - 6px;

    &__context {
      background-color: #fff;
      box-shadow: 0 2px 10px 0 rgba(180,180,180,0.10);
      border-radius: 6px;
      font-size: 14px;
      color: #9c9c9c;
      line-height: 20px;
      position: relative;
      padding: 12px 16px;
      word-break: break-all;
      display: inline-block;
      width: 100%;

      strong {
        color: @primary-color;
        font-weight: normal;
        margin-right: 3px;
      }

      &.q {
        
        em {
          color: #CC4E3F;
          font-size: 18px;
          font-style: normal;
          font-weight: bold;
          padding-right: 2px;

          &+sub {
            bottom: 0;
            font-size: 125%;
            padding: 0 3px 0 2px;
            color: #ccc;
          }
        }

        span {
          b {
            color: #CC4E3F;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            float: right;
            text-align: right;
            letter-spacing: .2px;

            & > sub {
              top: 0;
              color: #9c9c9c;
              font-weight: normal;
            }
          }
        }

        .van-tag {
          background-color: rgba(255,68,0,0.10);
          border-radius: 10px;
          font-size: 12px;
          line-height: 20px;
          color: #CC4E3F;
          padding: 0 .5em;
        }

        &:after {
          content: ''
        }
      }

      &.s {
        padding: 16px 16px 16px + 18px + 8px;
        font-family: SourceHanSerifCN-Regular;
        min-height: 88px;

        &:after {
          content: '';
          background-image: url(/static/image/byzx_text.png);
          background-size: 100% auto;
          position: absolute;
          bottom: 12px;
          right: 15px;
          width: 70px;
          height: 24px;
          opacity: 0.1;
        }
      }
    }

    &__tags {
      margin-right: -12px;

      .y .van-icon {
        color: #22A796;
      }

      .n .van-icon {
        color: #E7A22B;
      }

      p {
        margin-bottom: 0;
      }

      span {
        color: #B4B4B4;
        font-size: 12px;
        display: inline-block;
        margin-bottom: 6px;
        margin-right: 12px;
      }

      .van-icon {
        vertical-align: middle
      }
    }
  }

  .note {
    padding-bottom: 30px;
  }

  .other {
    padding-bottom: 25px;

    .van-swipe {
      line-height: 0;
    }
  }

  .tips {
    padding-bottom: 50px;
  }

  .flow-favor {
    text-align: right;

    &__inner {
      background-color: #fff;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      box-shadow: 0 2px 5px rgba(0,0,0,.25);
      display: block;
      text-align: center;
      display: inline-block;
      margin-right: 20px;

      .van-icon {
        line-height: 40px;
      }

      &.collected .van-icon {
        color: rgba(252,207,10,1)
      }
    }
  }
  
  .van-swipe-item .van-image {
    border-radius: 4px;
    overflow: hidden;
    max-width: 720px;
  }
}
</style>